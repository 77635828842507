import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'http://localhost:8080/api/test/';

class UserService {
  getPublicContent() {
    return axios.get(API_URL + 'all');
  }

  getData(){
    var bodyFormData = new FormData();
    bodyFormData.append('wsfunction','local_autoservice_get_log');
    bodyFormData.append('wstoken',process.env.REACT_APP_MOODLE_TOKEN);
    bodyFormData.append('auth',authHeader());
    return axios
      .post(process.env.REACT_APP_URL_MOODLE, bodyFormData)
      .then(response => {
        return response.data;
      });
  }

  getUserBoard() {
    return axios.get(API_URL + 'user', { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(API_URL + 'mod', { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + 'admin', { headers: authHeader() });
  }
}

export default new UserService();
