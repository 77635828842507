import React, { useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { trackPromise } from 'react-promise-tracker';
import EnrolService from '../../services/enrol.service';
import EventBus from "../../common/EventBus";
import * as XLSX from "xlsx";
import { saveAs } from 'file-saver';

function ExportReport() {
    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(true);

    function onClickReport(){
        setLoader(false);
        trackPromise(
            EnrolService.getReport().then(
                response => {
                    setLoader(true);
                    setData(response);
                    console.log(response);
                    if (response!='') {
                        response.forEach(el => {
                            delete el["concat(user_id,user_affected)"];
                        });
                    }
                    const wb = XLSX.utils.book_new();
                    const ws = XLSX.utils.json_to_sheet(response==''?[]: response);
                    XLSX.utils.book_append_sheet(wb,ws,'Hoja1');
                    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
                    const excelData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                    setData(false);
                    saveAs(excelData,'report.xlsx');

                },
                error => {
                //setError(error);
                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
                }
            )
        , 'area-export-report');
    }

    


    return (
        <>
            <button type="button" 
              className="btn btn-success btn-lg btn-block mb-3" onClick={onClickReport}>
                {
                    !loader && (
                        <span className="spinner-border spinner-border-sm"></span>
                    )
                }
                {
                    loader && (
                        <>
                        <FontAwesomeIcon icon={faFileExcel} /> Exportar
                        </>
                    )
                }
                
            </button>
        </>
    );

}


export default ExportReport;