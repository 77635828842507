import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import { MutatingDots } from 'react-loader-spinner';
import "./spinner.css";

export const Spinner = (props) => {
  const { promiseInProgress } = usePromiseTracker({area: props.area, delay: 0});

  return (
    promiseInProgress && (
      <div className="spinner">
        <MutatingDots ariaLabel="mutating-dots-loading" 
            color="#019df4" 
            secondaryColor="#5cb615" 
            radius="12.5" 
            height="100" width="100" />
      </div>
    )
  );
};