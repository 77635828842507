import axios from "axios";


class AuthService {
  login(username, password) {
    var bodyFormData = new FormData();
    bodyFormData.append('wsfunction','local_autoservice_login');
    bodyFormData.append('wstoken',process.env.REACT_APP_MOODLE_TOKEN);
    bodyFormData.append('username',username);
    bodyFormData.append('password',password);
    return axios
      .post(process.env.REACT_APP_URL_MOODLE, bodyFormData)
      .then(response => {
        //console.log(response);
        if (response.data.status === 1) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }else{
          localStorage.removeItem("user");
        }
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(username, email, password) {
    return axios.post(process.env.REACT_APP_URL_MOODLE + "signup", {
      username,
      email,
      password
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));;
  }
}

export default new AuthService();
