import React, { Component } from "react";
import { Routes, Route, Link } from "react-router-dom";
//import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut } from "@fortawesome/free-solid-svg-icons";

import AuthService from "./services/auth.service";

import Login from "./components/login/login.component";
import Home from "./components/home/home.component";

import EventBus from "./common/EventBus";

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      currentUser: undefined,
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user
      });
    }
    
    EventBus.on("logout", () => {
      this.logOut();
    });
  }

  componentWillUnmount() {
    EventBus.remove("logout");
  }

  logOut() {
    AuthService.logout();
    console.log('salioooo');
    this.setState({
      currentUser: undefined,
    });
  }

  render() {
    const { currentUser } = this.state;

    return (
      <div>
        {currentUser && (

          <nav className="navbar navbar-expand-lg">
            <div className="container">
              <Link to={"/home"} className="navbar-brand">
                <img src="./genio-logo-light.svg" className="d-inline-block align-top" alt="GENIO" />
              </Link>
              <div className="collapse navbar-collapse justify-content-between" id="navbarNav">
                <ul className="navbar-nav">
                  <li className="nav-item active">
                    <Link to={"/home"} className="nav-link">Inicio</Link>
                  </li>
                </ul>
                <ul className="navbar-nav">
                  <li className="nav-link">
                      <span className="nav-link">
                        {currentUser.firstname} {currentUser.lastname}
                      </span>
                  </li>
                  <li className="nav-link">
                    <a href="/login" className="btn btn-danger" onClick={this.logOut}>
                      <FontAwesomeIcon icon={faSignOut} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          
        )}

        <div className="container">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </div>

        {/* <AuthVerify logOut={this.logOut}/> */}
      </div>
    );
  }
}

export default App;
